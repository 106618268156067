.wrapper {
  margin: 10px;
  max-width: 300px;
  text-align: center;

  svg {
    width: 60%;
    margin: 0 auto 20px;
  }
  img {
    margin: 0 auto;
  }
  a {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
  .reviewLink {
    display: inline-block;
    text-transform: capitalize;
  }
}

.ratingBreakdown {
  flex: 1;
}

.ratingContainer {
  display: inline-block;
  position: relative;
  width: 60px;
  margin: 2px;
  cursor: pointer;

  .ratingText {
    position: absolute;
    text-align: center;
    margin-top: 35%;
    font-size: 20px;
    top: 0;
    left: 0;
  }
}

.customerReviewContainer {
  width: 100%;
  max-height: 300px;
  padding-right: 10px;
  overflow: scroll;

  .ratingContainer {
    width: 40px;
    cursor: none;
  }
  .customerRatingContainer {
    margin-top: 10px;
  }
  .comments {
    font-size: 90%;
  }
  .customerReview {
    width: 100%;
  }
  .customerName {
    font-weight: 600;
  }
  .itemDescription {
    font-size: 80%;
    color: #bbb;
  }
  .ratingText {
    position: absolute;
    margin-top: 35%;
    font-size: 15px;
    top: 0;
    left: 0;
  }
}

.ratingText {
  font-size: 72px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  width: 100%;
  margin: 10px 0;
}

